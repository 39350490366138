<template>
  <div>
    <div class="mass-action-settings">
      <el-form
        size="small"
        ref="editForm"
        label-position="top"
      >


        <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_name')" prop="name">
          <el-input
            style="width: 600px"
            maxlength="255"
            show-word-limit
            v-model="actionData.name"></el-input>
        </el-form-item>
        <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_desc')" prop="desc">
          <el-input
            style="width: 600px"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 6}"
            v-model="actionData.desc"></el-input>
        </el-form-item>



        <el-form-item label="Шкала" prop="parent_id">
          <el-select
            v-model="scale.parent_id"
          >
            <el-option
              v-for="template in scaleTemplates"
              :key="template.id"
              :label="template.name"
              :value="template.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Единица измерения" prop="unit_id">
          <el-select
            v-model="scale.unit_id"
            filterable
          >
            <el-option
              v-for="unit in scalesUnits"
              :key="unit.id"
              :label="unit.symbol"
              :value="unit.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Вес КПЭ(план)" prop="weight_plan">
          <el-input
            v-model="scale.weight_plan"
            v-number-format="{decimals: 2}"
            style="width: 400px;"
          ></el-input>
        </el-form-item>


        <el-form-item label="Целевой уровень показателя" prop="target_level">
          <el-input
            v-model="scale.target_level"
            v-number-format="{decimals: 6}"
            style="width: 400px;"
          ></el-input>
        </el-form-item>

        <el-form-item label="Промежуточное выполнение" prop="forecast">
          <el-input
            v-model="scale.forecast"
            v-number-format="{decimals: 6}"
            style="width: 400px;"
          ></el-input>
        </el-form-item>

        <el-form-item label="Факт выполнения" prop="fact">
          <el-input
            v-model="scale.fact"
            v-number-format="{decimals: 6}"
            style="width: 400px;"
          ></el-input>
        </el-form-item>


        <el-form-item label="% скорр" prop="correction">
          <el-input
            v-model="scale.correction"
            v-number-format="{decimals: 2}"
            style="width: 400px;"
          ></el-input>
        </el-form-item>


      </el-form>


      <el-popconfirm
        style=""
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите изменить КПЭ?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Изменить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import LibraryEditForm from "@/componentsCompany/tmk/elementScales/general/libraryEditForm.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import requestSender from "@/api/base/requestSender";

export default {
  name: "card-element-edit-action",
  mixins: [ActionTemplateMixin],
  components: {CardsStageNegotiationSelect, MassActionResult, CardsStageSelect, LibraryEditForm},

  computed: {
    actionButtonDisabled() {
      return false;
    }
  },

  mounted() {

    requestSender('get', 'scale/templates-list', {})
      .then(data => {
        this.scaleTemplates = data.scales;
      })

    this.$api.library.getScalesUnits()
      .then(data => {
        this.scalesUnits = data.scales_units;
      })
  },
  data() {
    return {
      scaleTemplates: [],
      scalesUnits: [],
      actionData: {
        name: null,
        desc: null,
      },
      scale: {
        parent_id: null,
        unit_id: null,
        weight_plan: null,
        target_level: null,
        forecast: null,
        fact: null,
        correction: null,
      },
    }
  },
  methods: {
    doAction() {
      let data = {};
      for (const [prop, value] of Object.entries(this.actionData)) {
        if (value) {
          data[prop] = value;
        }
      }
      data.scale = {};
      for (const [prop, value] of Object.entries(this.scale)) {
        if (value) {
          data.scale[prop] = value;
        }
      }
      this.$emit('do-action', data);
    }
  },
}
</script>
